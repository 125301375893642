














import Vue from "vue";
import Component from "vue-class-component";
import StatPickerOption from "@/pb/StatPickerOption.ts";
import {ModelSync, Prop, Watch} from "vue-property-decorator";
import _some from "lodash.some";
import StatHelpers from "@/pb/StatHelpers";


@Component
export default class StatPicker extends Vue {
    allValues: StatPickerOption[] = StatHelpers.STATS;

    @ModelSync('modelValue', 'update:modelValue', {type: String})
    statValue!: string;

    @Prop({type: Array})
    valueWhitelist!: string[] | undefined;

    @Prop({type: String})
    label!: string;

    myStats: StatPickerOption[] = [];

    mounted() {
        this.myStats = [StatHelpers.getStatByValue(this.statValue)!];
    }

    isInWhitelist(stat: string) {
        return this.valueWhitelist === undefined || this.valueWhitelist.length === 0 ? true : _some(this.valueWhitelist, s => s.toLowerCase() === stat.toLowerCase());
    }

    @Watch('myStats')
    onStatsChange(a: StatPickerOption[]) {
        this.statValue = a[0].value;
    }
}
