export default class StringUtils {
    private static readonly camelCaseReg = /([A-Z]|[a-z])([a-z]*)/g;

    static humanize(txt: string) {
        let res = "";
        for (const parts of txt.matchAll(this.camelCaseReg)) {
            if (res.length > 0) {
                res += ' ';
            }
            res += `${parts[1].toUpperCase()}${parts[2]}`;
        }
        return res;
    }
}