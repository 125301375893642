import StatPickerOption from "@/pb/StatPickerOption";
import StatContexts from "@/pb/StatContexts";
import {Stack} from "mnemonist";
import StringUtils from "@/common/utils/StringUtils";

const columnSet = new Set('column');
const columnContexts: StatContexts = {whitelist: columnSet};


const tileSet = new Set('tile');
const tileContexts: StatContexts = {whitelist: tileSet};

export default class StatHelpers {

    static readonly TOTAL_STAT_TYPES = [
        {postfix: 'All', prefix: 'Total', value: `all`},
        {postfix: 'Inbound', prefix: 'Inbound', value: `inbound`},
        {postfix: 'Outbound', prefix: 'Outbound', value: `outbound`}
    ];
    static readonly TOTAL_CALLS_GROUP: StatPickerOption =
        {
            pickerLabel: 'Total Calls',
            fullLabel: 'Total Calls',
            value: 'totalCallsGroup',
            children: [
                {fullLabel: 'Total Calls', pickerLabel: 'All', value: "totalCalls"},
                {fullLabel: 'Inbound Calls', pickerLabel: 'Inbound', value: "incomingCalls"},
                {fullLabel: 'Outbound Calls', pickerLabel: 'Outbound', value: "outgoingCalls"}
            ]
        };
    public static readonly STATS_BY_VALUE: Map<string, StatPickerOption> = new Map<string, StatPickerOption>();

    public static readonly STATS: StatPickerOption[] = [
        {
            pickerLabel: "Columns",
            value: "cols",
            contexts: columnContexts,
            children: [{
                pickerLabel: 'Name',
                fullLabel: 'Name',
                value: 'name',
                contexts: columnContexts
            },
                {
                    pickerLabel: 'Time Away',
                    fullLabel: 'Time Away',
                    value: 'timeAway',
                    contexts: columnContexts
                },
                {
                    pickerLabel: 'Extension',
                    fullLabel: 'Extension',
                    value: 'number'
                },
                {
                    pickerLabel: 'Status',
                    value: 'statusGroup',
                    children: [
                        {
                            pickerLabel: 'Full',
                            fullLabel: 'Status',
                            value: 'status'
                        },
                        {
                            fullLabel: 'Icon-only Status',
                            pickerLabel: 'Icon-only',
                            value: 'simpleStatus'
                        }
                    ]
                },
                {
                    pickerLabel: 'Avatar',
                    fullLabel: 'Avatar',
                    value: 'profileImage',
                    contexts: columnContexts
                },

            ]
        },
        {
            pickerLabel: 'Queue MemberShip',
            value: 'queueMem',
            children: [

                {
                    pickerLabel: 'Global Status',
                    fullLabel: 'Global Q Status',
                    value: 'globalQueueLogin'
                },
                {
                    pickerLabel: 'Member Of Total',
                    fullLabel: 'Queues Member Of',
                    value: 'queuesMemberOf'
                },
                {
                    pickerLabel: 'Logged In Total',
                    fullLabel: 'Queues Logged In',
                    value: 'queuesLoggedIn'
                },
            ]
        },
        {
            value: "live",
            pickerLabel: "Live",
            children: [
                {
                    fullLabel: 'Agents Active',
                    pickerLabel: 'Agents Active',
                    value: 'agentsActive'
                },
                {
                    fullLabel: 'Agents Busy',
                    pickerLabel: 'Agents Busy',
                    value: 'agentsBusy'
                },
                {
                    pickerLabel: "Agent Queue Log-In",
                    value: "agentQueue",
                    children: [
                        {
                            fullLabel: 'Agents Logged In',
                            pickerLabel: 'Logged-In Total',
                            value: 'agentsLoggedIn'
                        },
                        {
                            fullLabel: 'Agent Logged Out',
                            pickerLabel: 'Logged-Out Total',
                            value: 'agentsLoggedOut'
                        },
                    ]
                },
                {
                    pickerLabel: 'Live Calls',
                    fullLabel: 'Live Calls',
                    value: 'activeCalls',
                    children: StatHelpers.autoStatChildren('activeCalls')
                },
                {
                    pickerLabel: 'Ringing Calls',
                    fullLabel: 'Ringing Calls',
                    value: 'waitingCalls',
                    children: StatHelpers.autoStatChildren('waitingCalls')
                },
                {
                    pickerLabel: 'Talking Calls',
                    fullLabel: 'Talking Calls',
                    value: 'talkingCalls',
                    children: StatHelpers.autoStatChildren('talkingCalls')
                },
                {
                    pickerLabel: 'Current Max Wait Time',
                    fullLabel: 'Current Max Wait Time',
                    value: 'currentMaxWaitTime'
                },
            ]
        },
        {
            pickerLabel: 'SLA',
            value: "sla",
            children: [
                {
                    fullLabel: 'Total Calls over SLA',
                    pickerLabel: 'Total Calls Over',
                    value: 'totalCallsOverSla'
                },
                {
                    fullLabel: 'Total Calls under SLA',
                    pickerLabel: 'Total Calls Under',
                    value: 'totalCallsUnderSla'
                },
                {
                    fullLabel: 'Current Max Ring Time over SLA',
                    pickerLabel: 'Current Max Ring Time Over',
                    value: 'currentMaxWaitTimeOverSla'
                },
            ]
        },
        StatHelpers.TOTAL_CALLS_GROUP,
        {
            pickerLabel: 'Answered Calls',
            value: 'answeredCalls',
            children: StatHelpers.autoStatChildren('answeredCalls', 'answeredPercent')
        },
        {
            pickerLabel: 'Abandoned Calls',
            value: 'abandonedCalls',
            children: StatHelpers.autoStatChildren('abandonedCalls', 'abandonedPercent')
        },
        {
            pickerLabel: 'Overflowed Calls',
            fullLabel: 'Overflowed Calls',
            value: 'overflowedCalls',
            contexts: tileContexts
        },
        {
            pickerLabel: 'Total Callbacks',
            fullLabel: 'Total Callbacks',
            value: 'totalCallbacks',
            contexts: tileContexts
        },
        {
            pickerLabel: 'Ring Times',
            value: 'waitTimes',
            children: [
                {
                    pickerLabel: 'Max',
                    value: 'maxWaitTime',
                    children: StatHelpers.autoStatChildren('maxWaitTime'),
                },
                {
                    pickerLabel: 'Average',
                    value: 'averageWaitTime',
                    children: StatHelpers.autoStatChildren('averageWaitTime'),
                },
                {
                    pickerLabel: 'Total',
                    value: 'totalWaitTime',
                    children: StatHelpers.autoStatChildren('totalWaitTime'),
                }
            ]
        },
        {
            pickerLabel: 'Talk Times',
            value: 'talkTimes',
            children: [
                {
                    pickerLabel: 'Average',
                    value: 'averageTalkTime',
                    children: StatHelpers.autoStatChildren('averageTalkTime'),
                },
                {
                    pickerLabel: 'Total Talk Time',
                    value: 'totalTalkTime',
                    children: StatHelpers.autoStatChildren('totalTalkTime'),
                }
            ]
        },
    ];

    public static getStatFullName(opt: StatPickerOption) {
        let name = opt.fullLabel;
        if (opt.parent !== undefined && name != 'All') {
            name = `${opt.parent.fullLabel}: ${name}`;
        }
        return name;
    }

    public static getStatPickerName(opt: StatPickerOption) {
        return opt.pickerLabel;
    }

    public static getStatByValue(value: string): StatPickerOption | undefined {
        return this.STATS_BY_VALUE.get(value.toLowerCase());
    }

    public static getStatFullNameByValue(value: string): string | undefined {
        const stat = this.getStatByValue(value);
        if (stat === undefined)
            return undefined;

        return this.getStatFullName(stat);
    }

    public static getStatPickerNameByValue(value: string): string | undefined {
        const stat = this.getStatByValue(value);
        return stat?.pickerLabel;
    }

    static autoStatChildren(prefix: string, percentPrefix?: string): StatPickerOption[] {
        const parts: StatPickerOption[] = [];
        const labelPrefix = StringUtils.humanize(prefix);
        for (const ty of this.TOTAL_STAT_TYPES) {
            const fullLabel = `${ty.prefix} ${labelPrefix}`;
            const statPickerLabel = ty.postfix;
            parts.push({fullLabel: fullLabel, pickerLabel: statPickerLabel, value: `${prefix}:${ty.value}`});
            if (percentPrefix)
                parts.push({
                    fullLabel: `${fullLabel} %`,
                    pickerLabel: `${statPickerLabel} %`,
                    value: `${percentPrefix}:${ty.value}`
                })
        }

        return parts;
    }


    static forEachStats(stats: StatPickerOption[], cb: (opt: StatPickerOption) => void) {
        const arrsToProcess = new Stack<StatPickerOption[]>();
        arrsToProcess.push(stats);

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const items = arrsToProcess.pop()
            if (items === undefined)
                break;

            for (const item of items) {
                cb(item);
                if (item.children) {
                    arrsToProcess.push(item.children);
                }
            }
        }
    }
}


StatHelpers.forEachStats(StatHelpers.STATS, opt => {
    StatHelpers.STATS_BY_VALUE.set(opt.value.toLowerCase(), opt);
});

console.log("STATS_BY_VAL", StatHelpers.getStatFullNameByValue('status'));